<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert variant="danger" v-if="!invoiceData" show>
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No data found with this file id. Check
        <b-link class="alert-link" :to="{ name: 'file-list' }">
          File List
        </b-link>
        for other records.
      </div>
    </b-alert>

    <b-row v-if="invoiceData" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">Trucking Operations Report</h3>
                </div>
                <p class="card-text mb-25">Office Address</p>
                <p class="card-text mb-25">City, State, Country</p>
                <p class="card-text mb-0">Contact Information</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">Report<span class="invoice-number"> #{{ invoiceData.id }}</span></h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Date Issued:</p>
                  <p class="invoice-date">{{ invoiceData.issuedDate }}</p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Due Date:</p>
                  <p class="invoice-date">{{ invoiceData.dueDate }}</p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6">
                <h6 class="mb-2">Report For:</h6>
                <h6 class="mb-25">{{ invoiceData.client.name }}</h6>
                <p class="card-text mb-25">{{ invoiceData.client.company }}</p>
                <p class="card-text mb-25">{{ invoiceData.client.address }}, {{ invoiceData.client.country }}</p>
                <p class="card-text mb-25">{{ invoiceData.client.contact }}</p>
                <p class="card-text mb-0">{{ invoiceData.client.companyEmail }}</p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col cols="12" xl="6" class="d-flex justify-content-xl-end">
                <div>
                  <h6 class="mb-2">Summary:</h6>
                  <p>Total Due: <span class="font-weight-bold">{{ invoiceData.totalDue }}</span></p>
                  <p>Bank Name: {{ invoiceData.paymentDetails.bankName }}</p>
                  <p>Country: {{ invoiceData.paymentDetails.country }}</p>
                  <p>IBAN: {{ invoiceData.paymentDetails.iban }}</p>
                  <p>SWIFT: {{ invoiceData.paymentDetails.swiftCode }}</p>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Task Table -->
          <b-table-lite responsive :items="invoiceData.tasks" :fields="['taskTitle', 'taskDescription', 'rate', 'hours', 'total']">
            <template #cell(taskTitle)="data">
              <b-card-text class="font-weight-bold mb-25">{{ data.item.taskTitle }}</b-card-text>
            </template>
            <template #cell(taskDescription)="data">
              <b-card-text class="text-nowrap">{{ data.item.taskDescription }}</b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Total -->
              <b-col cols="12" md="6" class="d-flex justify-content-md-end">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">${{ invoiceData.subtotal }}</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Discount:</p>
                    <p class="invoice-total-amount">${{ invoiceData.discount }}</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Tax:</p>
                    <p class="invoice-total-amount">{{ invoiceData.taxPercentage }}%</p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">${{ invoiceData.total }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>{{ invoiceData.note }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Action Buttons -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <b-button v-b-toggle.sidebar-send-invoice variant="primary" class="mb-75" block>Send Invoice</b-button>
          <b-button variant="outline-secondary" class="mb-75" block @click="downloadInvoice">Download</b-button>
          <b-button variant="outline-secondary" class="mb-75" block @click="printInvoice">Print</b-button>
          <b-button variant="outline-secondary" class="mb-75" block :to="{ name: 'edit-invoice', params: { id: invoiceData.id } }">Edit</b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import axios from 'axios'
import {
    serverUri
} from '@/config'


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    Logo
  },
  data() {
    return {
      invoiceData: null,
    }
  },
  created() {
    this.fetchInvoiceData()
  },
  methods: {
    async fetchInvoiceData() {
      const fileId = decodeURIComponent(this.$route.params.fileId)
      try {
        const response = await axios.get(`${serverUri}/settlement/getreportdata/`, {fileId})
        this.invoiceData = response.data.invoice
      } catch (error) {
        console.error('Error fetching report data:', error)
        this.invoiceData = null
      }
    },
    printInvoice() {
      window.print()
    },
    downloadInvoice() {
      // Logic for downloading the report as a PDF or another format
    }
  }
}
</script>

<style scoped>
.invoice-preview-wrapper {
  padding: 20px;
}

.invoice-padding {
  padding: 1.25rem;
}

.invoice-spacing {
  margin-bottom: 2rem;
}

.invoice-preview-card {
  box-shadow: none;
  border: none;
}

.invoice-logo {
  font-size: 2rem;
}

.invoice-total-item {
  margin-bottom: 1rem;
}

.invoice-total-title {
  font-weight: bold;
}

.invoice-total-amount {
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
